import React, {
    useEffect,
    useContext,
    createContext,
    ReactNode,
    useReducer,
} from 'react';

import { StatusCodes } from 'http-status-codes';

// Models
import { FetchWrapperDataResponse } from 'HammerTemplate/CommonFunctions/FetchWrapper';
import {
    AllRequestResults,
    AllRequestResultsClass,
} from 'Modules/Models/AllRequestResults';
import { ServiceStatusRequest } from 'Modules/Models/ServiceStatusModel';
import { RequestStatusSummary } from 'Modules/Models/RequestStatusSummary';
import { UserType } from 'Modules/Models/User';

// HTTP
import {
    getCreditCountAsync,
    getServiceStatusAsync,
} from 'Modules/Http/FrontEndHttpUtils';
import {
    getLookupCachedDataAsync,
    getCanUserSendRequest,
} from 'Modules/Http/LookupHttpUtils';

import { useUserContext } from 'Modules/Seeker/UserContextProvider';

type ApplicationStateType = {
    isPaused: boolean;
    creditCount: FetchWrapperDataResponse<number>;
    serviceStatus: FetchWrapperDataResponse<ServiceStatusRequest>;
    results: FetchWrapperDataResponse<AllRequestResults>;
    requestStatus: FetchWrapperDataResponse<RequestStatusSummary>;
};

type ApplicationContextType = ApplicationStateType & {
    triggerUpdate: () => void;
    triggerInstantEntry: (
        input: updateIndex<AllRequestResults, keyof AllRequestResults>
    ) => void;
};

function defaultFetchWrapper<T>(input: T): FetchWrapperDataResponse<T> {
    return {
        error: false,
        statusCode: StatusCodes.OK,
        data: input,
    };
}

const Default_AllRequestResult = new AllRequestResultsClass();

// Create the Context
const ApplicationContext = createContext<ApplicationContextType>({
    isPaused: false,
    creditCount: null,
    serviceStatus: null,
    results: defaultFetchWrapper(Default_AllRequestResult),
    requestStatus: null,
    triggerUpdate: () => void 0,
    triggerInstantEntry: () => void 0,
});

function useApplicationContext() {
    return useContext(ApplicationContext);
}

type updateIndex<T, K extends keyof T> = {
    index: K;
    value: T[K];
};

// Context Reducer
type ReducerAction =
    | { set: ApplicationStateType }
    | updateIndex<AllRequestResults, keyof AllRequestResults>
    | undefined;

type ReducerFunctionType = (
    state: ApplicationStateType,
    action: ReducerAction
) => ApplicationStateType;

function reduceForm(
    state: ApplicationStateType,
    action: ReducerAction
): ApplicationStateType {
    if (action) {
        if ('set' in action) {
            return action.set;
        } else if ('index' in action && 'value' in action) {
            let hold = state.results?.data ?? Default_AllRequestResult;

            let value = action.value;
            if (value != null) {
                let list = hold[action.index] ?? [];
                hold = {
                    ...hold,
                    [action.index]: [...list, ...value],
                };
            }

            return {
                ...state,
                results: defaultFetchWrapper(hold),
                isPaused: true,
                requestStatus: defaultFetchWrapper({
                    canSendRequest: false,
                    pendingRequestType: undefined,
                }),
            };
        }
    }
    return state;
}

type ApplicationContextProviderProps = {
    children?: ReactNode;
    pollingTimeMs?: number;
};
/**
 * Component to initialize and house LookupResultsContext
 * @component
 * @category Settings
 * @subcategory State
 */
function ApplicationContextProvider({
    children,
    pollingTimeMs: pollingTime = 5000,
}: ApplicationContextProviderProps) {
    const [applicationVariableReducer, dispatch] = useReducer<
        ReducerFunctionType,
        ReducerAction
    >(reduceForm, undefined, () => {
        return {
            isPaused: false,
            creditCount: null,
            serviceStatus: null,
            results: null,
            requestStatus: null,
        };
    });

    const user = useUserContext();

    /**
     * Gets Saved Configurations
     */
    async function syncUserLookupsFromBackend() {
        const cacheLookup =
            user.userLevel === UserType.User || user.userLevel === UserType.OrganizationAdmin
                ? getLookupCachedDataAsync()
                : null;
        const serviceStatusLookup = getServiceStatusAsync();
        const creditCountLookup = getCreditCountAsync();
        const requestStatusLookup =
            user.userLevel === UserType.User || user.userLevel === UserType.OrganizationAdmin ? getCanUserSendRequest() : null;

        const cacheLookupResults = await cacheLookup;
        const serviceStatusLookupResult = await serviceStatusLookup;
        const creditCountLookupResult = await creditCountLookup;
        const requestStatus = await requestStatusLookup;

        dispatch({
            set: {
                isPaused: false,
                creditCount: creditCountLookupResult,
                serviceStatus: serviceStatusLookupResult,
                results: cacheLookupResults,
                requestStatus: requestStatus,
            },
        });
    }

    /**
     * Loads data on first load
     * Called on Mount - loads all user settings from backend
     */
    useEffect(() => {
        syncUserLookupsFromBackend();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Fetches cache data from backend, is paused when lookup request
     * is sent
     */
    useEffect(() => {
        const intervalId = setTimeout(() => {
            if (!applicationVariableReducer.isPaused) {
                syncUserLookupsFromBackend();
            }
        }, pollingTime);

        return () => {
            clearInterval(intervalId);
        };
    }, [applicationVariableReducer, pollingTime]);

    return (
        <ApplicationContext.Provider
            value={{
                ...applicationVariableReducer,
                triggerUpdate: syncUserLookupsFromBackend,
                triggerInstantEntry: (
                    input: updateIndex<
                        AllRequestResults,
                        keyof AllRequestResults
                    >
                ) => {
                    if (input != null) {
                        dispatch(input);
                    }
                },
            }}
        >
            {children}
        </ApplicationContext.Provider>
    );
}

export default ApplicationContextProvider;
export { ApplicationContext, useApplicationContext };
