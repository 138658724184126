import { lazy, LazyExoticComponent } from 'react';

// Material UI Icon
import HomeIcon from '@mui/icons-material/Home';
import HistoryIcon from '@mui/icons-material/History';
import ListAltIcon from '@mui/icons-material/ListAlt';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';

// Hammer Template
import { PageGroup } from 'HammerTemplate/HammerTemplateTypes';

// Models
import { User, UserType } from 'Modules/Models/User';
import { PermissionSet } from 'Modules/Models/PermissionSet';

// Pages
const HomePage: LazyExoticComponent<() => JSX.Element> = lazy(
    () => import('Modules/Pages/Overview/HomePage')
);

// Base User Pages
const HistoryPage = lazy(() => import('Modules/Pages/Account/HistoryPage'));
const SchedulePage = lazy(() => import('Modules/Pages/Account/SchedulePage'));
const ChangePasswordPage = lazy(
    () => import('Modules/Pages/Account/ChangePasswordPage')
);

const ResetOTPPage = lazy(() => import('Modules/Pages/Account/ResetOTPPage'));

const E164LookupPage = lazy(
    () => import('Modules/Pages/InfoLookup/E164LookupPage')
);
const ImsiLookupPage = lazy(
    () => import('Modules/Pages/InfoLookup/IMSILookupPage')
);
const MsisdnLookupPage = lazy(
    () => import('Modules/Pages/InfoLookup/MSISDNLookupPage')
);

const Auth2GPage = lazy(() => import('Modules/Pages/AuthLookup/Auth2GPage'));
const Auth3GPage = lazy(() => import('Modules/Pages/AuthLookup/Auth3GPage'));
const Auth4GPage = lazy(() => import('Modules/Pages/AuthLookup/Auth4GPage'));

const IMSILocationLookupPage = lazy(
    () => import('Modules/Pages/LocationLookup/IMSILocationLookupPage')
);
const MSISDNLocationLookupPage = lazy(
    () => import('Modules/Pages/LocationLookup/MSISDNLocationLookupPage')
);

// Super Admin
const OrganizationManagementPage = lazy(
    () =>
        import('Modules/Pages/Management/SuperAdmin/OrganizationManagementPage')
);

const SuperAdminUserManagementPage = lazy(
    () =>
        import(
            'Modules/Pages/Management/SuperAdmin/SuperAdminUserManagementPage'
        )
);

// Org Admin
const UserManagementPage = lazy(
    () => import('Modules/Pages/Management/OrgAdmin/UserManagementPage')
);

const OrgHistoryPage = lazy(
    () => import('Modules/Pages/Management/OrgAdmin/OrgHistoryPage')
);
const OrgAuditPage = lazy(
    () => import('Modules/Pages/Management/OrgAdmin/OrgAuditPage')
);

/**
 * Generate the available Pages
 * Get Permissions
 * @returns
 */
function pageSetGeneratorAsync(user: User | null): PageGroup[] {
    let permissionSet = user?.permissionSet;

    let pageSet: PageGroup[] = [];
    switch (user?.userLevel) {
        case UserType.SuperAdmin:
            pageSet.push({
                title: 'Management',
                route: '/management',
                pages: [
                    {
                        title: 'Organization',
                        route: '/organization',
                        page: OrganizationManagementPage,
                    },
                    {
                        title: 'Users',
                        route: '/users',
                        page: SuperAdminUserManagementPage,
                    },
                ],
            });
            break;
        case UserType.OrganizationAdmin:
            pageSet.push({
                title: 'Home',
                route: '/home',
                page: HomePage,
                Icon: HomeIcon,
            });
            
            if (permissionSet) {
                PushLookupPages(pageSet, permissionSet);
            }
            
            // User Management
            pageSet.push({
                title: 'User Management',
                route: '/user',
                page: UserManagementPage,
                Icon: ManageAccountsIcon,
            });
            
            // Organization History
            pageSet.push({
                title: 'History',
                route: '/historyOrg',
                page: OrgHistoryPage,
                Icon: HistoryIcon,
            });
            
            // Audit Log
            pageSet.push({
                title: 'Audit Log',
                route: '/audit',
                page: OrgAuditPage,
                Icon: ListAltIcon,
            });

            // Scheduled Lookups
            if (
                permissionSet?.msisdnLocationLookup ||
                permissionSet?.imsiLocationLookup
            ) {
                pageSet.push({
                    title: 'Scheduled Lookups',
                    route: '/scheduled',
                    page: SchedulePage,
                    Icon: CalendarMonthIcon,
                });
            }
            
            break;
        
        case UserType.User:
            pageSet.push({
                title: 'Home',
                route: '/home',
                page: HomePage,
                Icon: HomeIcon,
            });

            if (permissionSet) {
                PushLookupPages(pageSet, permissionSet);
            }

            pageSet.push({
                title: 'History',
                route: '/history',
                page: HistoryPage,
                Icon: HistoryIcon,
            });

            if (
                permissionSet?.msisdnLocationLookup ||
                permissionSet?.imsiLocationLookup
            ) {
                pageSet.push({
                    title: 'Scheduled Lookups',
                    route: '/scheduled',
                    page: SchedulePage,
                    Icon: CalendarMonthIcon,
                });
            }

            break;

        default:
            break;
    }

    //Other
    pageSet.push({
        title: 'Account',
        route: '/account',
        pages: [
            {
                title: 'Change Password',
                route: '/pwd',
                page: ChangePasswordPage,
            },
            {
                title: 'Reset 2FA',
                route: '/otp',
                page: ResetOTPPage,
            },
        ],
    });

    return pageSet;
}

function PushLookupPages(pageSet: PageGroup[], permissionSet: PermissionSet)
{
    let lookupPages: PageGroup;
    let locationPages: PageGroup;
    let authPages: PageGroup;

    // Lookups
    lookupPages = {
        title: 'Lookups',
        route: '/lookups',
        pages: [],
    };

    if (permissionSet?.imsiLookup) {
        lookupPages.pages.push({
            title: 'IMSI Lookup',
            route: '/imsi',
            page: ImsiLookupPage,
        });
    }
    if (permissionSet?.msisdnLookup) {
        lookupPages.pages.push({
            title: 'MSISDN Lookup',
            route: '/msisdn',
            page: MsisdnLookupPage,
        });
    }
    if (permissionSet?.e164Lookup) {
        lookupPages.pages.push({
            title: 'MSISDN Details Lookup ',
            route: '/details',
            page: E164LookupPage,
        });
    }

    if (lookupPages.pages.length > 0) {
        pageSet.push(lookupPages);
    }

    // Location
    locationPages = {
        title: 'Location',
        route: '/location',
        pages: [],
    };

    if (permissionSet?.imsiLocationLookup) {
        locationPages.pages.push({
            title: 'IMSI Location Lookup',
            route: '/imsi',
            page: IMSILocationLookupPage,
        });
    }
    if (permissionSet?.msisdnLocationLookup) {
        locationPages.pages.push({
            title: 'MSISDN Location Lookup',
            route: '/msisdn',
            page: MSISDNLocationLookupPage,
        });
    }

    if (locationPages.pages.length > 0) {
        pageSet.push(locationPages);
    }

    // Auth
    authPages = {
        title: 'Authentication ',
        route: '/auth',
        pages: [],
    };

    if (permissionSet?.auth2gLookup) {
        authPages.pages.push({
            title: '2G Auth Lookup',
            route: '/2g-auth',
            page: Auth2GPage,
        });
    }
    if (permissionSet?.auth3gLookup) {
        authPages.pages.push({
            title: '3G Auth Lookup',
            route: '/3g-auth',
            page: Auth3GPage,
        });
    }
    if (permissionSet?.authLteLookup) {
        authPages.pages.push({
            title: '4G Auth Lookup',
            route: '/4g-auth',
            page: Auth4GPage,
        });
    }
    if (authPages.pages.length > 0) {
                pageSet.push(authPages);
            }
}
export default pageSetGeneratorAsync;
