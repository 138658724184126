import axios from 'axios';

// Models
import { User } from 'Modules/Models/User';
import { PermissionSet } from 'Modules/Models/PermissionSet';
import { ServiceStatusRequest } from 'Modules/Models/ServiceStatusModel';

// Authentication
import { getAuthHeader } from 'HammerTemplate/Authentication/AuthenticatedFetch';
import FetchDataWrapperAsync, {
    FetchWrapperDataResponse,
} from 'HammerTemplate/CommonFunctions/FetchWrapper';

async function getVersion(): Promise<string | null> {
    let response = await FetchDataWrapperAsync<string>(() =>
        axios.get(`/api/System/Version`, getAuthHeader())
    );

    if (response && !response.error && response.data) {
        return response.data;
    }
    return null;
}

async function getLoggedInUserAsync(): Promise<User | null> {
    let response = await FetchDataWrapperAsync<User>(() =>
        axios.get(`/api/v1/user`, getAuthHeader())
    );

    if (response && !response.error && response.data) {
        return response.data;
    }
    return null;
}

async function getPermissionSetAsync(): Promise<PermissionSet | null> {
    let response = await FetchDataWrapperAsync<PermissionSet>(() =>
        axios.get(`/api/v1/permissions`, getAuthHeader())
    );

    if (response && !response.error && response.data) {
        return response.data;
    }
    return null;
}

async function getCreditCountAsync(): Promise<
    FetchWrapperDataResponse<number>
> {
    return FetchDataWrapperAsync<number>(() =>
        axios.get(`/api/v1/credit`, getAuthHeader())
    );
}

async function getServiceStatusAsync(): Promise<
    FetchWrapperDataResponse<ServiceStatusRequest>
> {
    return FetchDataWrapperAsync<ServiceStatusRequest>(() =>
        axios.get(`/api/v1/service-status`, getAuthHeader())
    );
}

export {
    getVersion,
    getPermissionSetAsync,
    getCreditCountAsync,
    getServiceStatusAsync,
    getLoggedInUserAsync,
};
