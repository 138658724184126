import React, { useState } from 'react';

// Material UI
import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider/Divider';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

// Template
import BasePage from 'HammerTemplate/PageTemplates/BasePage';
import AuthService from 'HammerTemplate/Authentication/AuthService';

function WarningPage() {
    const theme = useTheme();
    const [accepted, setAccepted] = useState<boolean>(false);

    return (
        <Dialog
            open={!accepted}
            fullWidth
            slotProps={{
                backdrop: {
                    style: { backgroundColor: theme.palette.primary.main },
                },
            }}
        >
            <DialogTitle textAlign={'center'} variant="h3">
                ATTENTION
            </DialogTitle>
            <DialogContent dividers>
                <Typography paragraph={true} align="center">
                    All individuals using this application and it's associated
                    content are required to protect it from unauthorized
                    disclosure.
                </Typography>
                <Typography paragraph={true} align="center">
                    Handling, storage, reproduction, and disposition of the
                    application content must be in accordance with 32 CFR Part
                    2002 and applicable agency policy.
                </Typography>
                <Typography paragraph={true} align="center">
                    Access to and the dissemination of Controlled Unclassified
                    Information shall be allowed as necessary and permissable to
                    any individual(s), organization(s) or grouping(s) of users,
                    provided such access or dissemination is consistent with or
                    in furtherance of Lawful Government Purpose and in a manner
                    consistent with applicable law, regulations, and
                    government-wide policies.
                </Typography>
                <Stack
                    justifyContent="center"
                    alignItems="center"
                    sx={{ backgroundColor: 'indigo', padding: '2rem' }}
                >
                    <Typography textAlign={'center'} variant="h1" color="white">
                        CUI
                    </Typography>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Stack
                    sx={{ width: '100%' }}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Button
                        variant="contained"
                        onClick={() => AuthService.doLogout()}
                    >
                        Log Out
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => setAccepted(true)}
                    >
                        Accept
                    </Button>
                </Stack>
            </DialogActions>
        </Dialog>
    );
}

export default WarningPage;
