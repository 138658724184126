import React from 'react';

// Material UI
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';

// Icons
import LogoutIcon from '@mui/icons-material/Logout';

// Auth
import AuthService from 'HammerTemplate/Authentication/AuthService';

// Models
import { UserType, UserTypeToString } from 'Modules/Models/User';

// Seeker
import { useUserContext } from 'Modules/Seeker/UserContextProvider';
import { useApplicationContext } from 'Modules/Seeker/ApplicationContextProvider';
import { requestTypeToString } from 'Modules/Models/Request';

function StatusBar() {
    const user = useUserContext();
    const { creditCount, serviceStatus, requestStatus } =
        useApplicationContext();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            sx={{ width: '100%' }}
        >
            <Stack
                direction="row"
                justifyContent="space-around"
                alignItems="center"
                sx={{ width: '100%' }}
            >
                <Typography variant="h6">
                    Service Status:{' '}
                    <b>
                        {serviceStatus?.data?.statusColor === 'GREEN'
                            ? 'Online'
                            : 'Offline'}
                    </b>
                </Typography>
                {(user.userLevel === UserType.User || user.userLevel === UserType.OrganizationAdmin) && (
                    <Typography variant="h6">
                        Lookup Status:{' '}
                        {!requestStatus?.error && (
                            <b>
                                {requestStatus?.data?.canSendRequest ? (
                                    'Ready'
                                ) : (
                                    <React.Fragment>
                                        Pending{' '}
                                        {requestTypeToString(
                                            requestStatus?.data
                                                ?.pendingRequestType
                                        )}{' '}
                                        <CircularProgress
                                            color="inherit"
                                            size="1rem"
                                        />
                                    </React.Fragment>
                                )}
                            </b>
                        )}
                    </Typography>
                )}
                <Typography variant="h6">
                    Credits Remaining : <b>{creditCount?.data}</b>
                </Typography>
            </Stack>
            <Stack direction="row" spacing={2} alignItems="center">
                <Stack
                    direction="column"
                    // justifyContent="flex-end"
                    // alignItems="center"
                >
                    <Typography variant="h6">{user.userName}</Typography>

                    {user.userLevel !== UserType.User && (
                        <Typography variant="subtitle2">
                            {UserTypeToString(user.userLevel)}
                        </Typography>
                    )}
                </Stack>
                <IconButton color="inherit" size="large" onClick={handleClick}>
                    <Avatar sx={{ bgcolor: 'secondary.main' }} />
                </IconButton>
                <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                    <MenuItem onClick={() => AuthService.doLogout()}>
                        <ListItemIcon>
                            <LogoutIcon fontSize="small" />
                        </ListItemIcon>
                        Sign Out
                    </MenuItem>
                </Menu>
            </Stack>
        </Stack>
    );
}

export default StatusBar;
