import axios from 'axios';

// Models
import { RequestStatus } from 'Modules/Models/Request';
import { AllRequestResults } from 'Modules/Models/AllRequestResults';
import { RequestStatusSummary } from 'Modules/Models/RequestStatusSummary';

// Authentication
import { getAuthHeader } from 'HammerTemplate/Authentication/AuthenticatedFetch';
import FetchDataWrapperAsync, {
    FetchWrapperDataResponse,
} from 'HammerTemplate/CommonFunctions/FetchWrapper';

function clearCacheAsync(): Promise<FetchWrapperDataResponse<RequestStatus>> {
    return FetchDataWrapperAsync(() =>
        axios.put(`/api/lookup/v1/clear-cache`, {}, getAuthHeader())
    );
}

function exportCacheAsync(): Promise<FetchWrapperDataResponse<string>> {
    return FetchDataWrapperAsync<string>(() =>
        axios.get(`/api/lookup/v1/export-cache`, getAuthHeader())
    );
}

async function getLookupCachedDataAsync(): Promise<
    FetchWrapperDataResponse<AllRequestResults>
> {
    return FetchDataWrapperAsync<AllRequestResults>(() =>
        axios.get(`/api/lookup/v1/pull-users-cache-data`, getAuthHeader())
    );
}

async function getCanUserSendRequest(): Promise<
    FetchWrapperDataResponse<RequestStatusSummary>
> {
    return FetchDataWrapperAsync<RequestStatusSummary>(() =>
        axios.get(`/api/lookup/v1/request-status`, getAuthHeader())
    );
}

export {
    clearCacheAsync,
    exportCacheAsync,
    getLookupCachedDataAsync,
    getCanUserSendRequest,
};
