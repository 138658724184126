export enum RequestType {
    Auth2gLookup = 'Auth2gLookup',
    Auth3gLookup = 'Auth3gLookup',
    AuthLteLookup = 'AuthLteLookup',
    E164Lookup = 'E164Lookup',
    ImsiLocationLookup = 'ImsiLocationLookup',
    ImsiLookup = 'ImsiLookup',
    MsisdnLocationLookup = 'MsisdnLocationLookup',
    MsisdnLookup = 'MsisdnLookup',
    ServiceStatus = 'ServiceStatus',
}

export type RequestStatus = {
    status:
        | 'Success'
        | 'Error'
        | 'InternalError'
        | 'InsufficientNumberOfCredits'
        | 'UnAuthorized';
    id: string;
    requestType: RequestType;
    errorMessage?: string;
};

export enum ErrorResponseType {
    NoError = 'NoError',

    ErrorResponse = 'ErrorResponse',
    SystemError = 'SystemError',
}

export interface LookupResponse {
    id: number | string;
    scheduledLookupName: number;
}

export type PLMN = {
    mnc: number;
    mcc: number;
};

function requestTypeToString(requestType: RequestType | undefined | null) {
    if (requestType == null) {
        return '';
    }
    switch (requestType) {
        case RequestType.Auth2gLookup:
            return '2G Auth Lookup';
        case RequestType.Auth3gLookup:
            return '3G Auth Lookup';
        case RequestType.AuthLteLookup:
            return '4G Auth Lookup';
        case RequestType.E164Lookup:
            return 'MSISDN Details Lookup';
        case RequestType.ImsiLocationLookup:
            return 'IMSI Location Lookup';
        case RequestType.ImsiLookup:
            return 'IMSI Lookup';
        case RequestType.MsisdnLocationLookup:
            return 'MSISDN Location Lookup';
        case RequestType.MsisdnLookup:
            return 'MSISDN Lookup';
        case RequestType.ServiceStatus:
            return 'Service Status';
        default:
            return '';
    }
}

export { requestTypeToString };
