import React, { useEffect, useState } from 'react';

// Material UI
import { ThemeOptions, experimental_sx as sx } from '@mui/material/styles';

// Material UI - Imports necessary for CSS and Default Prop Customization
// see: https://mui.com/material-ui/about-the-lab/#typescript
import type {} from '@mui/x-data-grid/themeAugmentation';
import type {} from '@mui/x-data-grid-pro/themeAugmentation';

// Auth
import AuthService from 'HammerTemplate/Authentication/AuthService';
import { getVersion } from 'Modules/Http/FrontEndHttpUtils';

// Hammer Template
import HammerTemplate from 'HammerTemplate/HammerTemplate';
import WarningPage from 'Modules/Pages/Overview/WarningPage';
import SignalRProvider from 'HammerTemplate/CommonFunctions/SignalRProvider';

// Seeker
import pageSetGeneratorAsync from 'Modules/Seeker/PageSetGenerator';
import UserContextProvider, {
    UserContext,
} from 'Modules/Seeker/UserContextProvider';
import ApplicationContextProvider from 'Modules/Seeker/ApplicationContextProvider';
import StatusBar from 'Modules/Seeker/StatusBar';

const themeOptions: ThemeOptions = {
    palette: {
        primary: {
            main: '#003152',
            light: '#008ECC',
        },
        secondary: {
            main: '#f7931e',
        },
        error: {
            main: '#f44336',
        },
        success: {
            main: '##4caf50',
        },
        neutral: {
            main: '#eeeeee',
        },
    },
    typography: {
        fontFamily: 'Open Sans',
    },
    components: {
        MuiPaper: {
            styleOverrides: {
                root: sx({
                    borderColor: 'primary.light',
                }),
            },
        },
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    borderRadius: '1rem',
                },
            },
        },
        MuiDataGrid: {
            styleOverrides: {
                root: sx({
                    boxShadow: 3,
                    border: 1,
                    borderColor: 'primary.light',
                    '& .MuiDataGrid-columnHeaders': {
                        backgroundColor: 'neutral.main',
                    },
                }),
            },
        },
        MuiCard: {
            styleOverrides: {
                root: sx({
                    boxShadow: 3,
                    border: 1,
                    borderColor: 'primary.light',
                }),
            },
        },
    },
};
const SignalR_URL = '/hub';

function App() {
    const [version, setVersion] = useState<string>();
    // Logout on Leaving Page

    useEffect(() => {
        window.onbeforeunload = (event: BeforeUnloadEvent) => {
            event.preventDefault();
            return 'Are you sure?';
        };

        window.onunload = (event: Event) => {
            AuthService.doLogout();
        };

        return () => {
            AuthService.doLogout();
        };
    }, []);

    useEffect(() => {
        async function updateVersion() {
            let result = await getVersion();
            if (result) {
                setVersion(result);
            }
        }
        updateVersion();
    }, []);

    return (
        <SignalRProvider url={SignalR_URL}>
            {/*
                React StrictMode is a tool for highlighting potential problems in an application
                It does cause everything to initially render twice during dev, it was remove below
                the signalR provider to prevent duplicate sockets being created. 
                https://reactjs.org/docs/strict-mode.html
            */}
            <React.StrictMode>
                <UserContextProvider>
                    <ApplicationContextProvider>
                        <UserContext.Consumer>
                            {(user) => (
                                <HammerTemplate
                                    themeOptions={themeOptions}
                                    appTitle="Seeker"
                                    pageSetGenerator={() =>
                                        pageSetGeneratorAsync(user)
                                    }
                                    HeaderBarAddOn={StatusBar}
                                    sideBarProps={{
                                        sideBarGroupProps: {
                                            collapsibleGroups: true,
                                            defaultGroupsOpen: true,
                                        },
                                        sideBarFixed: false,
                                        defaultSideBarOpen: true,
                                    }}
                                    version={version}
                                    additionalComponents={<WarningPage />}
                                />
                            )}
                        </UserContext.Consumer>
                    </ApplicationContextProvider>
                </UserContextProvider>
            </React.StrictMode>
        </SignalRProvider>
    );
}
export default App;
