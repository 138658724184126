import { PermissionSet } from 'Modules/Models/PermissionSet';

export enum UserType {
    User = 'User',
    OrganizationAdmin = 'OrgAdmin',
    SuperAdmin = 'SuperAdmin',
}

export function UserTypeToString(user: UserType) {
    switch (user) {
        case UserType.User:
            return 'User';
        case UserType.OrganizationAdmin:
            return 'Org Admin';
        case UserType.SuperAdmin:
            return 'Super Admin';
        default:
            return '';
    }
}

export type User = {
    id: number;
    userName: string;
    userLevel: UserType;
    disabled: boolean;
    organizationId?: number;
    permissionSet: PermissionSet;
};

export type CreateUserModel = {
    userName: string;
    password: string;
    userLevel: UserType;
    permissionSet: PermissionSet;
};
