import { GridValidRowModel } from '@mui/x-data-grid-pro';

import {
    E164LookupRequest,
    E164LookupResponse,
} from 'Modules/Models/E164LookupModel';
import {
    AuthLookupRequest,
    AuthLookupResponse,
} from 'Modules/Models/AuthLookupModel';
import {
    AuthLteLookupRequest,
    AuthLteLookupResponse,
} from 'Modules/Models/AuthLteLookupModel';
import {
    ImsiLocationRequest,
    ImsiLocationResponse,
} from 'Modules/Models/ImsiLocationModel';
import {
    ImsiLookupRequest,
    ImsiLookupResponse,
} from 'Modules/Models/ImsiLookupModel';
import {
    MsisdnLocationRequest,
    MsisdnLocationResponse,
} from 'Modules/Models/MsisdnLocationModel';
import {
    MsisdnLookupRequest,
    MsisdnLookupResponse,
} from 'Modules/Models/MsisdnLookupModel';

export interface CacheEntry<RequestObj, ResponseObj> extends GridValidRowModel {
    id: number | string;
    requestDateTime: string;
    status: 'Pending' | 'Completed';
    request?: RequestObj;
    response?: ResponseObj;
    conclusion?: string;
    scheduledLookupName?: string;
}

export class AllRequestResultsClass {
    auth2gLookup?: CacheEntry<AuthLookupRequest, AuthLookupResponse>[] = [];
    auth3gLookup?: CacheEntry<AuthLookupRequest, AuthLookupResponse>[] = [];
    authLteLookup?: CacheEntry<AuthLteLookupRequest, AuthLteLookupResponse>[] =
        [];
    e164Lookup?: CacheEntry<E164LookupRequest, E164LookupResponse>[] = [];
    imsiLocationLookup?: CacheEntry<
        ImsiLocationRequest,
        ImsiLocationResponse
    >[] = [];
    imsiLookup?: CacheEntry<ImsiLookupRequest, ImsiLookupResponse>[] = [];
    msisdnLocationLookup?: CacheEntry<
        MsisdnLocationRequest,
        MsisdnLocationResponse
    >[] = [];
    msisdnLookup?: CacheEntry<MsisdnLookupRequest, MsisdnLookupResponse>[] = [];
}

export interface AllRequestResults extends AllRequestResultsClass {}
